<template>
  <transition name="page">
    <div
      :class="[
        { 'modal-open': isModalOpen },
        { 'is-device': isDevice },
        locale,
      ]">
      <Header />

      <main id="main">
        <CookieBar />

        <ContactModal />
        <QuickShopModal />
        <BackInStockModal />

        <Chat />

        <Nuxt />

        <Follow v-if="!hideFollow" />
      </main>

      <Footer />
      <!-- <script v-if="locale == 'en-ca'" defer src="https://static.myshlf.us/Affiliates/sms_aff_clicktrack.js"></script> -->
    </div>
  </transition>
</template>

<script>
  import { mapState, mapGetters } from 'vuex'
  import Cookie from 'js-cookie'

  import layoutMixin from '@/mixins/layoutMixin'

  import Header from '@/components/global/Header'
  import CookieBar from '@/components/global/CookieBar'
  import ContactModal from '@/components/global/ContactModal'
  import QuickShopModal from '@/components/shop/QuickShopModal'
  import BackInStockModal from '@/components/shop/BackInStockModal'
  import Chat from '@/components/global/Chat'
  import Follow from '@/components/global/Follow'
  import Footer from '@/components/global/Footer'

  export default {
    name: 'Default',

    mixins: [layoutMixin],

    components: {
      Header,
      CookieBar,
      ContactModal,
      QuickShopModal,
      BackInStockModal,
      Chat,
      Follow,
      Footer,
    },

    computed: {
      ...mapState({
        locale: state => state.locale,
        isModalOpen: state => state.modal.isOpen,
        email: state => state.user.data?.email,
        checkout: state => state.cart.checkout,
      }),

      ...mapGetters(['isLoggedIn']),

      hideFollow() {
        return (
          this.$route &&
          this.$route.matched.map(r => {
            if (r.components.default) {
              return r.components.default.options
                ? r.components.default.options.hideFollow
                : r.components.default.hideFollow
            }
          })[0]
        )
      },

      isDevice() {
        return process.client
          ? 'ontouchstart' in window ||
              navigator.maxTouchPoints > 0 ||
              navigator.msMaxTouchPoints > 0
          : false
      },
    },

    watch: {
      $route: {
        handler(to, from) {
          // if the route is the same but we've append query params to it,
          // we should not fire the $fb.pageView() method.
          if (to.path === from?.path) return false

          if (this.$fb) this.$fb.pageView()
        },
        immediate: true,
      },
    },

    beforeMount() {
      if (
        window.location.href.indexOf('tools/emails/click/gift-card-created') >
        -1
      ) {
        const queryString = window.location.search
        const urlParams = new URLSearchParams(queryString)
        const url = urlParams.get('url')
        window.location.replace(url)
      } else if (
        window.location.href.indexOf(
          'https://geebeauty.ca/tools/skt-scheduler'
        ) > -1
      ) {
        const url = window.location.href.replace(
          'https://geebeauty.ca/',
          'https://checkout.geebeauty.ca/'
        )
        window.location.replace(url)
      } else if (
        window.location.href.indexOf(
          'https://geebeauty.com/tools/skt-scheduler'
        ) > -1
      ) {
        const url = window.location.href.replace(
          'https://geebeauty.com/',
          'https://checkout.geebeauty.ca/'
        )
        window.location.replace(url)
      }
    },

    async created() {
      await this.$store.dispatch('fetchAdditionalGlobalData')

      if (!this.checkout) {
        await this.$store.dispatch('createShopifyCheckout')
      }

      this.$store.dispatch('setLocalStorageCartItems')

      if (this.$klaviyo) {
        this.$klaviyo.identifyCustomer(this.email)
      }

      if (this.$route && this.$route.query.login) {
        if (!this.isLoggedIn) {
          setTimeout(() => {
            this.$store.dispatch('openHeaderMenuExpand', 'login')
          }, 350)

          return
        }

        this.$router.push({ name: 'profile' })
      }
    },
  }
</script>
